import { AutoComplete, Divider, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TextLoop from "react-text-loop";
import { getAuthToken, hasTokenExpired } from "../../services/token.service";

// REDUX
import { organizationsSelector } from "../../slices/organization.slice";

// STYLES
import "./index.scss";

// TYPES
import { ITEToken, TOrganizationPublic } from "@timeedit/types/lib/types";
import { EOrganizationVisibility } from "@timeedit/types/lib/enums/organizationVisibility.enum";

const INTERNAL_PREFIXES = ["internal:", "private:"];

type TRenderItem = {
  label: JSX.Element;
  value?: string;
  options?: TRenderItem[];
};

const getOrgSelectorClass = (visibility: EOrganizationVisibility) => {
  if (visibility === EOrganizationVisibility.PRIVATE)
    return "org-selector--option-private";
  if (visibility === EOrganizationVisibility.HIDDEN)
    return "org-selector--option-hidden";

  return "org-selector--option";
};

const optionRenderItem = (
  id: string,
  name: string,
  region: string,
  visibility: EOrganizationVisibility
): TRenderItem => ({
  value: id,
  label: (
    <div className={getOrgSelectorClass(visibility)}>
      {name}
      <span>
        <div className="org-selector--option-region">{region}</div>
      </span>
    </div>
  ),
});

const dividerRenderItem = (): TRenderItem => ({
  label: (
    <Divider orientation="left" className="org-selector--divider">
      Other
    </Divider>
  ),
  options: [],
});

const transformOrganizations = (organizations: TOrganizationPublic[]) => {
  const renderItems = organizations.reduce(
    (acc: TRenderItem[], org: TOrganizationPublic, idx: number) => {
      if (idx > 0) {
        if (
          organizations[idx - 1].visibility ===
            EOrganizationVisibility.PUBLIC &&
          org.visibility !== EOrganizationVisibility.PUBLIC
        ) {
          acc.push(dividerRenderItem());
        }
      }
      acc.push(
        optionRenderItem(
          org.id,
          org.name,
          org.region.split("_")[0],
          org.visibility
        )
      );
      return acc;
    },
    []
  );

  return renderItems;
};

const findSearchPrefix = (searchString: string) => {
  const lowerCaseSearchString = searchString.toLocaleLowerCase();
  const prefix = INTERNAL_PREFIXES.find((prefix) =>
    lowerCaseSearchString.startsWith(prefix)
  );
  return prefix || "";
};

function OrgSelectionPage() {
  const history = useHistory();
  const token = getAuthToken();
  const tokenData: ITEToken | undefined = token
    ? JSON.parse(atob(token.split(".")[1]).toString())
    : undefined;

  // SELECTORS
  const organizations: TOrganizationPublic[] = useSelector(
    organizationsSelector
  );

  // STATE VARS
  const [searchString, setSearchString] = useState<string>("");
  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<string>("");
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    TRenderItem[]
  >(transformOrganizations(organizations));

  // EFFECTS
  useEffect(() => {
    if (selectedOrganizationId)
      history.push(`/organizations/${selectedOrganizationId}`);
  }, [selectedOrganizationId, history]);

  // EVENT HANDLERS
  const onSearch = (searchString: string) => {
    if (!searchString || searchString === "") {
      // todo: show previously selected organizations
      return setFilteredOrganizations(
        transformOrganizations(
          organizations.filter(
            (org) => org.visibility === EOrganizationVisibility.PUBLIC
          )
        )
      );
    }

    let normalizedSearchString = searchString.toLocaleLowerCase();

    const prefix = findSearchPrefix(searchString);
    const hasInternalSearchPrefix = prefix !== "";
    if (hasInternalSearchPrefix) {
      normalizedSearchString = normalizedSearchString
        .replace(prefix, "")
        .trimStart();
    }

    const filteredOrgs = organizations.filter((org) => {
      const partialNameMatch = org.name
        .toLocaleLowerCase()
        .includes(normalizedSearchString);
      const fullCustomerSignatureMatch =
        org.customerSignature.toLocaleLowerCase() === normalizedSearchString;
      const fullOrganizationIdMatch =
        org.id.toLocaleLowerCase() === normalizedSearchString;

      const isPrivateSearchAndPrivateOrg =
        hasInternalSearchPrefix &&
        org.visibility === EOrganizationVisibility.PRIVATE;
      if (partialNameMatch && isPrivateSearchAndPrivateOrg) {
        return true;
      }

      const isRegularSearchAndPublicOrHiddenOrg =
        !hasInternalSearchPrefix &&
        (org.visibility === EOrganizationVisibility.PUBLIC ||
          org.visibility === EOrganizationVisibility.HIDDEN);
      if (partialNameMatch && isRegularSearchAndPublicOrHiddenOrg) {
        return true;
      }
      const isExactCustomerSignatureOrOrgIdMatch =
        fullCustomerSignatureMatch || fullOrganizationIdMatch;
      if (isExactCustomerSignatureOrOrgIdMatch) {
        return true;
      }

      return false;
    });

    // Sort first by visibility, then by name
    filteredOrgs.sort((a, b) => {
      if (
        a.visibility === EOrganizationVisibility.PUBLIC &&
        b.visibility !== EOrganizationVisibility.PUBLIC
      ) {
        return -1;
      }
      if (
        a.visibility !== EOrganizationVisibility.PUBLIC &&
        b.visibility === EOrganizationVisibility.PUBLIC
      ) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });

    return setFilteredOrganizations(transformOrganizations(filteredOrgs));
  };

  const onChange = (searchString: string) => setSearchString(searchString);

  const onSelect = (selectedOrgId: string) => {
    // Find the organization
    const selectedOrg = organizations.find((org) => org.id === selectedOrgId);
    if (!selectedOrg) return;
    setSearchString(selectedOrg.name);
    setSelectedOrganizationId(selectedOrgId);
  };
  if (
    tokenData?.organizationId &&
    !selectedOrganizationId &&
    !hasTokenExpired(token)
  )
    onSelect(tokenData.organizationId);
  return (
    <div className="org-selector--wrapper">
      <Typography.Title level={3} style={{ marginBottom: 0, fontWeight: 500 }}>
        <TextLoop interval={2000}>
          <span>Welcome to</span>
          <span>Välkommen till</span>
          <span>Bienvenido a</span>
          <span>Velkommen til</span>
          <span>Welkom bij</span>
          <span>Bienvenue sur</span>
          <span>Nau mai ki</span>
        </TextLoop>
      </Typography.Title>
      <Typography.Title
        level={1}
        style={{
          marginTop: 0,
          color: "#00824C",
          fontSize: "44px",
          marginBottom: "4rem",
        }}
      >
        TimeEdit
      </Typography.Title>
      <Typography.Title level={2} style={{ marginBottom: 0 }}>
        Find your organization
      </Typography.Title>
      <Typography.Paragraph>
        Select your organization to get started.
      </Typography.Paragraph>
      <AutoComplete
        className="org-selector--input"
        value={searchString}
        options={filteredOrganizations}
        style={{ width: "100%" }}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={onChange}
        onFocus={() => onSearch(searchString)}
        placeholder="Search for your organization..."
      />
    </div>
  );
}

export default OrgSelectionPage;

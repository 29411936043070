import { Form, Input, Button, Alert, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';

// COMPONENTS
import LoadingSpinner from '../../components/LoadingSpinner';

// SERVICES
import { configService } from '../../services/config.service';
import { setAuthToken } from '../../services/token.service';

// HOOKS
import { useQuery } from '../../hooks/useQuery';

// STYLES
import './index.scss';
import { ETEIdPResponseStatus, parseTEIdPResponse } from '../../types/teIdPResponse.type';

// TYPES
function TEIdPAuthPage() {
  const query = useQuery();
  const history = useHistory();

  const [form] = Form.useForm();

  // STATE VARS
  const [authError, setAuthError] = useState<string | null | undefined>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  // MEMOIZED PROPS
  const { appId, organizationId, language, context, region } = useMemo(() => {
    const appId = query.get('appId') || null;
    const organizationId = query.get('organizationId') || null;
    const language = query.get('language') || null;
    const context = query.get('context') || null;
    const region = query.get('region') || null;
    return { appId, organizationId, language, context, region };
  }, [query]);

  const forgotPasswordUrl = useMemo(() => {
    const params = new URLSearchParams({ organizationId: organizationId || '' });
    return `/idp/forgot-password?${params.toString()}`;
  }, [organizationId]);

  // EVENT HANDLERS
  const onAuthenticate = async (values: any) => {
    setIsAuthenticating(true);
    setAuthError(null);
    // Make sure form is touched and has no errors
    if ((!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0) || !region) return;
    const { usernameOrEmail, password } = values;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Region': region,
      },
      body: JSON.stringify({
        usernameOrEmail,
        password,
        appId,
        organizationId,
        language,
        context,
      }),
    };
    const response = await fetch(`${configService.AUTH_SERVICE_URL}/idp/authenticate`, requestOptions);
    const jsonResponse = await response.json();
    const parsedResponse = parseTEIdPResponse(jsonResponse);
    setIsAuthenticating(false);
    if (parsedResponse.status === ETEIdPResponseStatus.SUCCESS) {
      setAuthToken(parsedResponse?.token || '');
      history.push(`/successful-authentication?${query.toString()}`);
    } else {
      setAuthError(parsedResponse.message)
    }
  }

  const disabledSignInButton = useMemo(() => !organizationId || !appId, [organizationId, appId]);

  return (
    <LoadingSpinner isLoading={isAuthenticating}>
      <div className="idp--wrapper">
        <Typography.Title level={2} style={{ marginBottom: 0 }}>Sign in.</Typography.Title>
        <Typography.Paragraph style={{ marginBottom: '1.6rem' }}>Authenticate with your TimeEdit credentials</Typography.Paragraph>
        {(!organizationId || !appId) && (
          <Alert
            message="Invalid organization or app"
            description="The selected organization or app is invalid. Please restart the authentication"
            type="error"
          />
        )}
        {authError && (
          <Alert
            message="Unable to authenticate you"
            description={authError}
            type="error"
          />
        )}
        <Form layout="vertical" form={form} onFinish={onAuthenticate} requiredMark={false}>
          <Form.Item name="usernameOrEmail" label="Username or email" rules={[{ required: true, message: "Please enter your email or username" }]}>
            <Input
              prefix={<UserOutlined />}
              placeholder="Email or username"
            />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: true, message: "Please enter your password" }]}>
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>
          <Button disabled={disabledSignInButton} block htmlType="submit" type="primary">Sign in</Button>
        </Form>
        <div className="idp--footer">
          {organizationId && <Link to={forgotPasswordUrl}>Forgot your password?</Link>}
        </div>
      </div>
    </LoadingSpinner>
  );
}

export default TEIdPAuthPage;

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// COMPONENTS
import MainLayoutComponent from './components/MainLayout';
import LoadingSpinner from './components/LoadingSpinner';

// PAGES
import OrgSelectionPage from './pages/OrgSelection/orgSelection.page';
import ProductSelectionPage from './pages/ProductSelection/prodSelection.page';
import AuthSelectionPage from './pages/AuthSelection/authSelection.page';
import TEIdPAuthPage from './pages/TEIdP/teIdp.page';
import TEIdPForgotPasswordPage from './pages/TEIdPForgotPassword/teIdPForgotPassword.page';
import TEIdPResetPasswordPage from './pages/TEIdPResetPassword/teIdPResetPassword.page';
import SuccessfulAuthPage from './pages/SuccessfulAuth/successfulAuth.page';
import SSOTestingPage from './pages/SSOTesting/ssoTesting.page';
import UnsuccessfulAuthPage from './pages/UnsuccessfulAuth/unsuccessfulAuth.page';

// REDUX
import { fetchOrganizations, organizationsInitialLoad, organizationsLoading } from './slices/organization.slice';

// STYLES
import 'antd/dist/antd.less';
import './App.scss';
import DeepLinkAuthPage from './pages/DeepLinkAuth';

function App() {
  const dispatch = useDispatch();

  // SELECTORS
  const isLoadingOrganizations = useSelector(organizationsLoading);
  const hasCompletedInitialLoad = useSelector(organizationsInitialLoad);

  useEffect(() => {
    dispatch(fetchOrganizations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <div className="te-auth--wrapper">
        <MainLayoutComponent>
          <LoadingSpinner isLoading={(!hasCompletedInitialLoad || isLoadingOrganizations)} renderChildrenWhileLoading={false}>
            <Switch>
              {/* MOUNTING POINT */}
              <Route path="/" exact>
                <OrgSelectionPage />
              </Route>
              {/* FLOW TO DETERMINE AUTHENTICATION METHOD */}
              <Route path="/organizations/:organizationId" exact>
                <ProductSelectionPage />
              </Route>
              <Route path="/organizations/:organizationId/products/:appId" exact>
                <AuthSelectionPage />
              </Route>
              {/* TE IDP PAGES */}
              <Route path="/idp" exact>
                <TEIdPAuthPage />
              </Route>
              <Route path="/idp/forgot-password" exact>
                <TEIdPForgotPasswordPage />
              </Route>
              <Route path="/idp/reset-password" exact>
                <TEIdPResetPasswordPage />
              </Route>
              {/* SUCCESSFUL AUTH PAGE WITH REDIRECT */}
              <Route path="/successful-authentication" exact>
                <SuccessfulAuthPage />
              </Route>
              {/* UNSUCCESSFUL SSO AUTH PAGE */}
              <Route path="/unsuccessful-authentication" exact>
                <UnsuccessfulAuthPage />
              </Route>
              {/* DEEP LINKED AUTH PAGE */}
              <Route path="/deep-link" exact>
                <DeepLinkAuthPage />  
              </Route>
              {/* SSO MANUAL TESTING PAGE */}
              <Route path="/sso-testing" exact>
                <SSOTestingPage />
              </Route>
            </Switch>
          </LoadingSpinner>
        </MainLayoutComponent>
      </div>
    </Router>
  );
}

export default App;

import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useParsedQuery(keys: string[], nullValue = null) {
  const { search } = useLocation();
  const values: (string | null)[] = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return keys.map((key) => {
      const val = urlParams.get(key);
      if (val && val !== 'null') return val;
      return nullValue;
    })
  }, [search, keys, nullValue]);
  return values;
}
import { IDefaultPaginatedResult, IDefaultPaginatedState, IDefaultReducerState } from "../types/state.interface";

export const finishedLoadingSuccess = (state: IDefaultReducerState): void => {
  state.loading = false;
  state.hasErrors = false;
};

export const finishedLoadingFailure = (state: IDefaultReducerState): void => {
  state.loading = false;
  state.hasErrors = true;
};

export const isLoadingRequest = (state: IDefaultReducerState): void => {
  state.loading = true;
  state.hasErrors = false;
};

export const stateHasFetchedAllObjectsSuccess = (payload: IDefaultPaginatedResult, state: IDefaultPaginatedState, createFn: Function): void => {
  const { results, page, limit, totalPages }: IDefaultPaginatedResult = payload;
  const iteratedResults = results.map(el => createFn(el));

  const map = iteratedResults.reduce((tot, acc) => ({
    ...tot,
    [acc.id]: acc,
  }), {});
  state.results = iteratedResults;
  state.map = map;
  state.page = page;
  state.limit = limit;
  state.totalPages = totalPages;
};
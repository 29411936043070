export enum EAuthStrategy {
  TE_IDP = 'TE_IDP',
  SAML2 = 'SAML2',
  CAS = 'CAS',
}

export interface IAuthConfig {
  id: string;
  name: string;
  organizationId: string;
  strategy: EAuthStrategy;
  default: boolean;
}

export class CAuthConfig {
  static create = (obj: any) => ({
    id: obj.id,
    name: obj.name,
    organizationId: obj.organizationId,
    strategy: obj.strategy,
    default: obj.default || false,
  })
}
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IAuthConfig } from '../../types/authConfig.type';
import { useParsedQuery } from '../../hooks/useQuery';
import { ERegion } from '@timeedit/types/lib/enums/region.enum';

type Props = {
  authConfig: IAuthConfig;
  region: ERegion
}

export const IdPAuthConfigButton = ({ authConfig, region }: Props) => {
  const { organizationId, appId }: { organizationId: string, appId: string} = useParams();
  const [language, context] = useParsedQuery(['language', 'context']);

  const idpUrl = useMemo(() => {
    const params = new URLSearchParams({ organizationId, appId, language: language || '', context: context || '', authConfigId: authConfig.id, region });
    return `/idp?${params.toString()}`;
  }, [organizationId, appId, language, context, authConfig, region]);

  return (
    <Link to={idpUrl} style={{textAlign:"center", display: "block"}}>
        Sign in with TimeEdit account
    </Link>
  );
};
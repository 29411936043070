import './index.scss';

const SSOIcon = () => {
  return (
    <div className="sso-icon--wrapper">
      SSO
    </div>
  );
};

export default SSOIcon;
import AppLogo from "../../assets/app_logo_text.svg";

// STYLES
import "./index.scss";

const TELogo = () => (
  <div className="te-logo--wrapper">
    <img src={AppLogo} alt="TimeEdit text logo" height={32} />
  </div>
);

export default TELogo;

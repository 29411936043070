import { configService } from "./config.service";
import Cookies from "js-cookie";
import { ITEToken } from '@timeedit/types/lib/types';

export const setAuthToken = (tokenString: string) => {
  localStorage.setItem(configService.AUTH_TOKEN_NAME, tokenString);
  Cookies.set(configService.AUTH_TOKEN_NAME, tokenString);
}

export const getAuthToken = () => {
  let token = localStorage.getItem(configService.AUTH_TOKEN_NAME);
  if(!token) Cookies.get(configService.AUTH_TOKEN_NAME)
  return token
}

export const clearAuthToken = () => {
  localStorage.removeItem(configService.AUTH_TOKEN_NAME);
  Cookies.remove(configService.AUTH_TOKEN_NAME);
}

export const hasTokenExpired = (token: string | null) => {
    if (!token) return true;

    const tokenData: ITEToken | undefined = token ? JSON.parse(atob(token.split('.')[1]).toString()) : undefined;
    const exp = tokenData?.exp || 0;
    const isTokenExpired = Date.now() > exp * 1000;
    return isTokenExpired
}

import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

// STYLES
import './index.scss';
import {clearAuthToken} from "../../services/token.service";

const BackButton = () => {
  const history = useHistory();
  return (
    <Button
      className="back-button--wrapper"
      type="link"
      icon={<ArrowLeftOutlined />}
      onClick={() => {
          clearAuthToken()
          history.goBack()
      }}
    >
      Back
    </Button>
  );
};

export default BackButton;
import { combineReducers } from 'redux';

// REDUCERS
import organizationReducer from './organization.slice';
import authConfigReducer from './authConfig.slice';

const rootReducer = combineReducers({
  organizations: organizationReducer,
  authConfigs: authConfigReducer,
});

export default rootReducer;
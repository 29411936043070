import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// STYLES
import './index.scss';

// TYPES
type Props = {
  isLoading: boolean,
  children: React.ReactNode,
  renderChildrenWhileLoading?: boolean,
};

const LoadingSpinner = ({ isLoading, children, renderChildrenWhileLoading = true }: Props) => {
  return (
    <>
      {isLoading && (
        <div className="loading-spinner--wrapper">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
      {(!isLoading || (isLoading && renderChildrenWhileLoading)) && children}
    </>
  );
};

export default LoadingSpinner;
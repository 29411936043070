// COMPONENTS
import TELogo from "../TELogo";

// STYLES
import "./index.scss";

const TopBar = () => {
  return (
    <div className="top-bar--wrapper">
      <TELogo />
      <div className="top-bar--right">
        <a href="https://academy.timeedit.com/" className="top-bar--link-btn">
          Knowledge bases
        </a>
        <a href="https://support.timeedit.net/" className="top-bar--link-btn">
          Support
        </a>
        <a href="https://www.timeedit.com" className="top-bar--button">
          Not a TimeEdit customer?
        </a>
      </div>
    </div>
  );
};

export default TopBar;

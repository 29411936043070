import { Form, Input, Select } from 'antd';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// SERVICES
import { configService } from '../../services/config.service';

// TYPES
import { EAppId } from '@timeedit/types/lib/enums';

function SSOTestingPage() {
  // INPUT PARAMS
  const [organizationId, setOrganizationId] = useState<string>('');
  const [appId, setAppId] = useState<EAppId>(EAppId.TE_CORE);
  const [authConfigId, setAuthConfigId] = useState<string | undefined>(undefined);
  const [language, setLanguage] = useState<string | undefined>(undefined);
  const [context, setContext] = useState<string | undefined>(undefined);

  // MEMOIZED PROPS
  const authUrl = useMemo(() => {
    const params = new URLSearchParams();
    if (organizationId)
      params.append('organizationId', organizationId);
    if (appId)
      params.append('appId', appId);
    if (authConfigId)
      params.append('authConfigId', authConfigId);
    if (language)
      params.append('language', language);
    if (context)
      params.append('context', context);
    return `${configService.AUTH_SERVICE_URL}/authenticate/?${params.toString()}`;
  }, [organizationId, appId, authConfigId, language, context]);

  const teIdpUrl = useMemo(() => {
    const params = new URLSearchParams();
    if (appId)
      params.append('appId', appId);
    if (authConfigId)
      params.append('authConfigId', authConfigId);
    if (context)
      params.append('context', context);
    return `/idp?${params.toString()}`;
  }, [authConfigId, appId, context]);

  return (
    <div className="auth--wrapper">
      <Form layout="vertical">
        <Form.Item label="OrganizationId">
          <Input value={organizationId} onChange={(e) => setOrganizationId(e.target.value)} />
        </Form.Item>
        <Form.Item label="App">
          <Select value={appId} onChange={(appId) => setAppId(appId)}>
            <Select.Option value={EAppId.TE_CORE}>TE Core</Select.Option>
            <Select.Option value={EAppId.TE_VIEWER}>TE Viewer</Select.Option>
            <Select.Option value={EAppId.TE_RESERVE}>TE Reserve</Select.Option>
            <Select.Option value={EAppId.TE_PLAN}>TE Plan</Select.Option>
            <Select.Option value={EAppId.TE_EXAM}>TE Exam</Select.Option>
            <Select.Option value={EAppId.TE_PREFERENCES}>TE Preferences</Select.Option>
            <Select.Option value={EAppId.TE_REPORTING}>TE Reporting</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Auth config id">
          <Input value={authConfigId} onChange={(e) => setAuthConfigId(e.target.value)} />
        </Form.Item>
        <Form.Item label="Language">
          <Input value={language} onChange={(e) => setLanguage(e.target.value)} />
        </Form.Item>
        <Form.Item label="Context">
          <Input value={context} onChange={(e) => setContext(e.target.value)} />
        </Form.Item>
        <a href={authUrl}>Login with SSO</a>
        <Link to={teIdpUrl}>Login with TE</Link>
      </Form>
    </div>
  );
}

export default SSOTestingPage;

import { createSlice } from '@reduxjs/toolkit'
import api from '../services/api.service';
import { groupBy } from 'lodash';
import { IDefaultPaginatedState, IState } from '../types/state.interface';
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';
import { AppDispatch } from '../index';
import { CAuthConfig, IAuthConfig } from '../types/authConfig.type';
import { EAppId, EExternalServices } from '@timeedit/types/lib/enums';
import { ERegion } from '@timeedit/types/lib/enums/region.enum';

export const initialState: IDefaultPaginatedState = {
  loading: false,
  hasErrors: false,
  results: [],
  map: {},
  page: 1,
  limit: 1000,
  totalPages: 1,
};

// Slice
const slice = createSlice({
  name: 'authConfigs',
  initialState,
  reducers: {
    fetchAuthConfigsRequest: state => {
      isLoadingRequest(state);
    },
    fetchAuthConfigsSuccess: (state, { payload }) => {
      const authConfigs = payload.results.map((p: any) => CAuthConfig.create(p));
      state.results = authConfigs;
      state.map = groupBy(authConfigs, 'id');
      finishedLoadingSuccess(state);
    },
    fetchAuthConfigsFailure: state => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const authConfigsLoading = (state: IState) => state.authConfigs.loading;
export const authConfigsSelector = (state: IState) => state.authConfigs.results as IAuthConfig[];
export const authConfigSelector = (authConfigId: string) => (state: IState) => state.authConfigs.map[authConfigId] as IAuthConfig;

// Actions
export const {
  fetchAuthConfigsRequest,
  fetchAuthConfigsSuccess,
  fetchAuthConfigsFailure,
} = slice.actions;

export const fetchAuthConfigsForOrganizationAndApp = (organizationId: string, appId: EAppId, region: ERegion) => async (dispatch: AppDispatch) => {
  try {
    dispatch(fetchAuthConfigsRequest());
    const result = await api.post({
      endpoint: `/organizations/${organizationId}/auth-configs/public`,
      data: { appId },
      service: EExternalServices.AUTH_SERVICE,
      headers: {'X-Region': region, 'access-control-allow-origin': '*'}
    });
    dispatch(fetchAuthConfigsSuccess(result));
  } catch (e: any) {
    dispatch(fetchAuthConfigsFailure());
    return console.error(e.message);
  }
};
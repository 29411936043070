import { useMemo } from "react";

// COMPONENTS
import Product from "../Product";

// STYLES
import './index.scss';

// TYPES
import { EProductBucket, IProduct, TEProductBuckets  } from "../../types/product.type";
import { Typography } from "antd";
import { EAppId } from "@timeedit/types/lib/enums";

type Props = {
  products: IProduct[],
  bucket: EProductBucket,
  onProductClick: (appId: EAppId) => void,
};

const ProductGrid = ({ products, bucket, onProductClick }: Props) => {
  const productBucket = useMemo(() => TEProductBuckets.find((b) => b.bucket === bucket), [bucket]);

  if (!productBucket) return null;

  return (
    <div className="product-grid--wrapper">
      <div className="product-grid--title">
        <Typography.Title level={5} style={{ marginBottom: '0' }}>{productBucket.title}</Typography.Title>
      </div>
      <div className="product-grid--row">
        {products.map((product) => <Product key={product.appId} appId={product.appId} onClick={onProductClick} />)}
      </div>
    </div>
  );
};

export default ProductGrid;


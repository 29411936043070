// Todo: remove the hard coded values and remove the environments.
// It stays for now since we need to support the old backend for some time.

export const configs = {
  development: {
    ORGANIZATION_SERVICE_URL: process.env.REACT_APP_ORGANIZATION_SERVICE_URL ?? 'http://localhost:3003/v1',
    AUTH_SERVICE_URL: process.env.REACT_APP_AUTH_SERVICE_URL ?? 'http://localhost:3001/v1',
    USER_SERVICE_URL: process.env.REACT_APP_USER_SERVICE_URL ?? 'http://localhost:3002/v1',
    AUTH_TOKEN_NAME:  process.env.REACT_APP_AUTH_TOKEN_NAME ?? 'te_auth_token',
    AUTH_MESSAGE_NAME: process.env.REACT_APP_AUTH_MESSAGE_NAME ?? 'te_auth_request'
  },
  beta: {
    ORGANIZATION_SERVICE_URL: process.env.REACT_APP_ORGANIZATION_SERVICE_URL ?? 'https://organizations.timeedit.dev/v1',
    AUTH_SERVICE_URL: process.env.REACT_APP_AUTH_SERVICE_URL ?? 'https://auth.timeedit.dev/v1',
    USER_SERVICE_URL: process.env.REACT_APP_USER_SERVICE_URL ?? 'https://users.timeedit.dev/v1',
    AUTH_TOKEN_NAME:  process.env.REACT_APP_AUTH_TOKEN_NAME ?? 'te_auth_token',
    AUTH_MESSAGE_NAME: process.env.REACT_APP_AUTH_MESSAGE_NAME ?? 'te_auth_request'
  },
  staging: {
    ORGANIZATION_SERVICE_URL: process.env.REACT_APP_ORGANIZATION_SERVICE_URL ?? 'https://organizations.timeedit.io/v1',
    AUTH_SERVICE_URL: process.env.REACT_APP_AUTH_SERVICE_URL ?? 'https://auth.timeedit.io/v1',
    USER_SERVICE_URL: process.env.REACT_APP_USER_SERVICE_URL ?? 'https://users.timeedit.io/v1',
    AUTH_TOKEN_NAME: process.env.REACT_APP_AUTH_TOKEN_NAME ?? 'te_auth_token',
    AUTH_MESSAGE_NAME: process.env.REACT_APP_AUTH_MESSAGE_NAME ?? 'te_auth_request'
  },
  production: {
    ORGANIZATION_SERVICE_URL: process.env.REACT_APP_ORGANIZATION_SERVICE_URL ?? 'https://organizations.timeedit.net/v1',
    AUTH_SERVICE_URL: process.env.REACT_APP_AUTH_SERVICE_URL ?? 'https://auth.timeedit.net/v1',
    USER_SERVICE_URL: process.env.REACT_APP_USER_SERVICE_URL ?? 'https://users.timeedit.net/v1',
    AUTH_TOKEN_NAME: process.env.REACT_APP_AUTH_TOKEN_NAME ?? 'te_auth_token',
    AUTH_MESSAGE_NAME: process.env.REACT_APP_AUTH_MESSAGE_NAME ?? 'te_auth_request'
  },
};

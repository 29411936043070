import { Typography } from 'antd';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { groupBy } from 'lodash';

// COMPONENTS
import ProductGrid from '../../components/ProductGrid';

// REDUX
import { organizationSelector } from '../../slices/organization.slice';

// STYLES
import './index.scss';

// TYPES
import { EProductBucket, TEProducts } from '../../types/product.type';
import { EAppId } from '@timeedit/types/lib/enums';

function ProductSelectionPage() {
  const { organizationId }: { organizationId: string } = useParams();
  const history = useHistory();
  // SELECTORS
  const organization = useSelector(organizationSelector(organizationId));

  // MEMOIZED PROPS
  const groupedProducts = useMemo(() => {
    const enabledProducts = organization?.enabledProducts || [];
    const filteredProducts = TEProducts.filter(
      (product) => enabledProducts.indexOf(product.appId) > -1 && Boolean(product.bucket),
    );
    return groupBy(filteredProducts, 'bucket');
  }, [organization]);

  // EVENT HANDLER
  const onAppClick = (appId: EAppId) => {
    history.push(`/organizations/${organizationId}/products/${appId}`);
  };

  return (
    <div className="prod-selector--wrapper">
      <Typography.Title level={4} style={{ marginBottom: 0 }}>
        Select product
      </Typography.Title>
      <div className="product-grid--wrapper">
        {groupedProducts[EProductBucket.CURRICULUM_MANANGEMENT] &&
          groupedProducts[EProductBucket.CURRICULUM_MANANGEMENT].length && (
            <ProductGrid
              bucket={EProductBucket.CURRICULUM_MANANGEMENT}
              products={groupedProducts[EProductBucket.CURRICULUM_MANANGEMENT]}
              onProductClick={onAppClick}
            />
          )}
        {groupedProducts[EProductBucket.WORKFORCE_PLANNING] &&
          groupedProducts[EProductBucket.WORKFORCE_PLANNING].length && (
            <ProductGrid
              bucket={EProductBucket.WORKFORCE_PLANNING}
              products={groupedProducts[EProductBucket.WORKFORCE_PLANNING]}
              onProductClick={onAppClick}
            />
          )}
        {groupedProducts[EProductBucket.SCHEDULING_AND_RESOURCE_MANAGEMENT] &&
          groupedProducts[EProductBucket.SCHEDULING_AND_RESOURCE_MANAGEMENT]
            .length && (
            <ProductGrid
              bucket={EProductBucket.SCHEDULING_AND_RESOURCE_MANAGEMENT}
              products={
                groupedProducts[
                  EProductBucket.SCHEDULING_AND_RESOURCE_MANAGEMENT
                  ]
              }
              onProductClick={onAppClick}
            />
          )}
        {groupedProducts[EProductBucket.PUBLISHING_AND_REGISTRATION] &&
          groupedProducts[EProductBucket.PUBLISHING_AND_REGISTRATION]
            .length && (
            <ProductGrid
              bucket={EProductBucket.PUBLISHING_AND_REGISTRATION}
              products={
                groupedProducts[EProductBucket.PUBLISHING_AND_REGISTRATION]
              }
              onProductClick={onAppClick}
            />
          )}
        {groupedProducts[EProductBucket.EVALUATION_AND_REPORTING] &&
          groupedProducts[EProductBucket.EVALUATION_AND_REPORTING].length && (
            <ProductGrid
              bucket={EProductBucket.EVALUATION_AND_REPORTING}
              products={
                groupedProducts[EProductBucket.EVALUATION_AND_REPORTING]
              }
              onProductClick={onAppClick}
            />
          )}
        {groupedProducts[EProductBucket.ADMINISTRATION] &&
          groupedProducts[EProductBucket.ADMINISTRATION].length && (
            <ProductGrid
              bucket={EProductBucket.ADMINISTRATION}
              products={groupedProducts[EProductBucket.ADMINISTRATION]}
              onProductClick={onAppClick}
            />
          )}
      </div>
    </div>
  );
}

export default ProductSelectionPage;

export interface IPasswordSheriffRule {
  name: string;
  errorMsg: string;
  required: boolean;
  validate: (password: string) => boolean;
};

export interface IPasswordSheriffReturn {
  strength: number,
  errors: string[],
}

export const PSLengthRule = (min: number, max: number, required: boolean = true): IPasswordSheriffRule => ({
  name: 'Length rule',
  errorMsg: `Between ${min} and ${max} characters`,
  required,
  validate: (password: string) => password.length >= min && password.length <= max, 
});

export const PSNumericRule = (required: boolean = false): IPasswordSheriffRule => ({
  name: 'Numeric rule',
  errorMsg: `At least one numerical character`,
  required,
  validate: (password: string) => (/([0-9])/.test(password)),
});

export const PSLowerCaseCharRule = (required: boolean = false): IPasswordSheriffRule => ({
  name: 'Lower case rule',
  errorMsg: `At least one lower case character`,
  required,
  validate: (password: string) => (/(?=.*[a-z])/.test(password)),
});

export const PSUpperCaseCharRule = (required: boolean = false): IPasswordSheriffRule => ({
  name: 'Upper case rule',
  errorMsg: `At least one upper case character`,
  required,
  validate: (password: string) => (/(?=.*[A-Z])/.test(password)),
});

export const PSSpecialCharRule = (required: boolean = false): IPasswordSheriffRule => ({
  name: 'Special characters rule',
  errorMsg: `At least one special character`,
  required,
  // eslint-disable-next-line no-useless-escape
  validate: (password: string) => (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)),
});

export const passwordSheriff = (password: string, rules: IPasswordSheriffRule[] = []): IPasswordSheriffReturn => {
  const pwSheriff = rules.reduce((retVal: IPasswordSheriffReturn, rule) => {
    const validatesRule = rule.validate(password);
    if (validatesRule) 
      return { strength: retVal.strength += 1, errors: retVal.errors };
    return { strength: retVal.strength, errors: [ ...retVal.errors, rule.errorMsg ] };
  }, { strength: 0, errors: []});
  return pwSheriff;
}
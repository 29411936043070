import { Form, Input, Button, Typography } from 'antd';
import { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';

// COMPONENTS
import LoadingSpinner from '../../components/LoadingSpinner';

// SERVICES
import { configService } from '../../services/config.service';

// STYLES
import './index.scss';
import { useParsedQuery } from '../../hooks/useQuery';
import { organizationSelector } from '../../slices/organization.slice';
import { useSelector } from 'react-redux';

function TEIdPForgotPasswordPage() {
  const [form] = Form.useForm();

  const [organizationId] = useParsedQuery(['organizationId']);
  const organization = useSelector(organizationSelector(organizationId || ''))

  // STATE VARS
  const [isResetting, setIsResetting] = useState(false);
  const [hasReset, setHasReset] = useState(false);

  // EVENT HANDLERS
  const onResetPassword = async (values: any) => {
    setIsResetting(true);
    // Make sure form is touched and has no errors
    if (!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0) return;
    const { usernameOrEmail } = values;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Region': organization?.region || '',
      },
      body: JSON.stringify({
        usernameOrEmail,
        organizationId
      }),
    };
    await fetch(`${configService.AUTH_SERVICE_URL}/idp/forgot-password`, requestOptions);
    setIsResetting(false);
    setHasReset(true);
  }

  return (
    <LoadingSpinner isLoading={isResetting}>
      {!hasReset && (
        <div className="idp--wrapper">
          <Typography.Title level={2} style={{ marginBottom: 0 }}>Forgot password.</Typography.Title>
          <Typography.Paragraph style={{ marginBottom: '1.6rem' }}>No worries! You'll be back in business in no time.</Typography.Paragraph>
          <Form layout="vertical" form={form} onFinish={onResetPassword} requiredMark={false}>
            <Form.Item name="usernameOrEmail" label="Username or email" rules={[{ required: true, message: "Please enter your email or username" }]}>
              <Input
                prefix={<UserOutlined />}
                placeholder="Email or username"
              />
            </Form.Item>
            <Button block htmlType="submit" type="primary">Reset password</Button>
          </Form>
        </div>
      )}
      {hasReset && (
        <div className="idp--wrapper">
          <Typography.Title level={2} style={{ marginBottom: 0 }}>Reset password email sent.</Typography.Title>
          <Typography.Paragraph style={{ marginBottom: '1.6rem' }}>Check your inbox for an email containing a link to complete the reset.</Typography.Paragraph>
        </div>
      )}
    </LoadingSpinner>
  );
}

export default TEIdPForgotPasswordPage;

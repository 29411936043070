import { Button } from 'antd';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// COMPONENTS
import SSOIcon from '../SSOIcon';

// HOOKS
import { useParsedQuery } from '../../hooks/useQuery';

// SERVICES
import { configService } from '../../services/config.service';

// TYPES
import { IAuthConfig } from '../../types/authConfig.type';
import { organizationSelector } from '../../slices/organization.slice';

type Props = {
  authConfig: IAuthConfig;
}

export const SSOAuthConfigButton = ({ authConfig }: Props) => {
  const { organizationId, appId }: { organizationId: string, appId: string} = useParams();
  const [language, context] = useParsedQuery(['language', 'context']);
  const organization = useSelector(organizationSelector(organizationId));

  const ssoUrl = useMemo(() => {
    const params = new URLSearchParams({
      organizationId: organization.id,
      appId,
      language: language || '',
      context: context || '',
      authConfigId: authConfig.id,
      region: organization.region,
    });
    return `${configService.AUTH_SERVICE_URL}/organizations/${organization.id}/authenticate?${params.toString()}`;
  }, [appId, language, context, authConfig, organization]);
  
  return (
    <a href={ssoUrl}>
      <Button
        icon={<SSOIcon />}
        block
        type="primary"
        style={{marginBottom: '4px'}}
      >
        Sign in with {authConfig.name}
      </Button>
    </a>
  );
};
// STYLES
import './index.scss';

// TYPES
type Props = {
  strength: number;
  acceptableStrength: number;
  maxStrength: number;
  errors: string[];
};

const calculateFillClass = (strength: number, acceptableStrength: number) => {
  const diff = acceptableStrength - strength;
  if (diff <= 0) return 'filled-ok';
  if (diff === 1) return 'filled-warning';
  if (diff > 1) return 'filled-error'; 
}

const PasswordStrength = ({ strength, acceptableStrength = 3, maxStrength, errors = [] }: Props) => {
  const renderMeterBars = () => {
    let bars = [];
    const fillClass = calculateFillClass(strength, acceptableStrength);
    for (let i = 0; i < maxStrength; i += 1) {
      bars.push(<div key={`pw-meter-bar-${i}`} className={`bar ${strength > i ? fillClass : 'not-filled'}`} />);
    }
    return bars;
  }
  return (
    <div className="password-strength--wrapper">
      <div className="password-strength--meter">
        {renderMeterBars()}
      </div>
      <div className="password-strength-errors">
        {(errors && errors.length > 0) && errors[0]}
      </div>
    </div>
  )
};

export default PasswordStrength;
import { createSlice } from '@reduxjs/toolkit'
import api from '../services/api.service';
import { IExtendedLoadingState, IState } from '../types/state.interface';
import {
  stateHasFetchedAllObjectsSuccess,
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';
import { AppDispatch } from '../index';
import { EAppId } from '@timeedit/types/lib/enums';
import { TOrganizationPublic } from '@timeedit/types/lib/types';
import { createOrganization } from '@timeedit/types/lib/utils/organization.util';

export const initialState: IExtendedLoadingState = {
  loading: false,
  hasErrors: false,
  results: [],
  map: {},
  page: 1,
  limit: 1000,
  totalPages: 1,
  hasCompletedInitialLoad: false,
};

// Slice
const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    fetchOrganizationsRequest: state => {
      isLoadingRequest(state);
    },
    fetchOrganizationsSuccess: (state, { payload }) => {
      stateHasFetchedAllObjectsSuccess(payload, state, createOrganization);
      finishedLoadingSuccess(state);
      state.hasCompletedInitialLoad = true;
    },
    fetchOrganizationsFailure: state => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const organizationsLoading = (state: IState) => state.organizations.loading;
export const organizationsSelector = (state: IState) => state.organizations.results as TOrganizationPublic[];
export const organizationSelector = (organizationId: string) => (state: IState) => state.organizations.map[organizationId] as TOrganizationPublic;
export const organizationsInitialLoad = (state: IState) => state.organizations.hasCompletedInitialLoad;
export const selectOrganizationFromCustomerSignatureAndAppId = (customerSignature: string | null, appId: EAppId | null) => (state: IState) => {
  if (!customerSignature || !appId) return null;
  const organization: TOrganizationPublic | undefined = state.organizations.results.find((org) => org.customerSignature === customerSignature);
  if (!organization) return null;
  if (organization.enabledProducts.includes(appId)) return organization;
  return null;
};

// Actions
export const {
  fetchOrganizationsRequest,
  fetchOrganizationsSuccess,
  fetchOrganizationsFailure,
} = slice.actions;

export const fetchOrganizations = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(fetchOrganizationsRequest());
    const result = await api.get({ endpoint: `/organizations/global` });
    dispatch(fetchOrganizationsSuccess(result));
  } catch (e: any) {
    dispatch(fetchOrganizationsFailure());
    return console.error(e.message);
  }
};
import { Alert, Typography } from 'antd';
import { useParsedQuery } from '../../hooks/useQuery';

// STYLES
import './index.scss';

const UnsuccessfulAuthPage = () => {
  const [message, description] = useParsedQuery(['message', 'description']);

  return (
    <div className="unsuccessful-auth--wrapper">
      <Typography.Title level={2} style={{ marginBottom: 0 }}>We're really sorry.</Typography.Title>
      <Typography.Paragraph style={{ marginBottom: '4rem' }}>We weren't able to authenticate you.</Typography.Paragraph>
      <Alert type="error" message={message} description={description} />
    </div>
  )
};

export default UnsuccessfulAuthPage;
export enum ETEIdPResponseStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
};

export interface ITEIdPResponse {
  status: ETEIdPResponseStatus,
  code: number,
  message?: string,
  token?: string,
};

export const parseTEIdPResponse = (jsonResponse: any, expectToken: boolean = true): ITEIdPResponse => {
  const { code, message, token } = jsonResponse;
  if (!token && expectToken) return { status: ETEIdPResponseStatus.FAILURE, code, message };
  const codeAsNumber = parseInt(code, 10);
  return {
    status: codeAsNumber >= 200 && codeAsNumber <= 300 ? ETEIdPResponseStatus.SUCCESS : ETEIdPResponseStatus.FAILURE,
    code: codeAsNumber,
    token,
    message
  };
};
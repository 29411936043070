import { Form, Input, Button, Alert, Typography } from 'antd';
import { useState } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';

// HOOKS
import { useParsedQuery } from '../../hooks/useQuery';

// COMPONENTS
import LoadingSpinner from '../../components/LoadingSpinner';
import PasswordStrength from '../../components/PasswordStrength';

// SERVICES
import { configService } from '../../services/config.service';

// STYLES
import './index.scss';

// TYPES
import { ETEIdPResponseStatus, ITEIdPResponse, parseTEIdPResponse } from '../../types/teIdPResponse.type';
import { IPasswordSheriffReturn, IPasswordSheriffRule, passwordSheriff, PSLengthRule, PSLowerCaseCharRule, PSNumericRule, PSSpecialCharRule, PSUpperCaseCharRule } from '../../utils/passwordSheriff.utils';

const pwSheriffRules: IPasswordSheriffRule[] = [
  PSLengthRule(8, 32, true),
  PSNumericRule(true),
  PSUpperCaseCharRule(true),
  PSLowerCaseCharRule(true),
  PSSpecialCharRule(true),
];

function TEIdPResetPasswordPage() {
  const [form] = Form.useForm();
  const history = useHistory();

  const [token, teUserId, region, organizationId] = useParsedQuery(['token', 'teUserId', 'region', 'organizationId']);

  // STATE VARS
  const [pwSheriff, setPWSheriff] = useState<IPasswordSheriffReturn>({ strength: 0, errors: [] });
  const [password, setPassword] = useState('');
  const [hasMatchingPasswords, setHasMatchingPasswords] = useState(false);

  const [resetError, setResetError] = useState<ITEIdPResponse | null>(null);
  const [isResetting, setIsResetting] = useState(false);
  const [successfulReset, setSuccessfulReset] = useState(false);

  // EVENT HANDLERS
  const onResetPassword = async () => {
    setIsResetting(true);
    setResetError(null);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Region': `${region}`,
      },
      body: JSON.stringify({
        token,
        password,
        teUserId,
        organizationId,
      }),
    };
    const response = await fetch(`${configService.AUTH_SERVICE_URL}/idp/reset-password`, requestOptions);
    setIsResetting(false);
    const jsonResponse = await response.json();
    const parsedResponse = parseTEIdPResponse(jsonResponse, false);
    if (parsedResponse.status === ETEIdPResponseStatus.SUCCESS) {
      setSuccessfulReset(true);
    } else {
      setSuccessfulReset(false);
      setResetError(parsedResponse);
    }
  }

  // EVENT HANDLERS
  const onPasswordChange = (password: string) => {
    const pwReturn = passwordSheriff(password, pwSheriffRules);
    setPWSheriff(pwReturn);
    setPassword(password);
  }

  const pwCheckValid = () => {
    return pwSheriff.strength === pwSheriffRules.length && hasMatchingPasswords;
  }

  return (
    <LoadingSpinner isLoading={isResetting}>
      {!successfulReset && (
        <div className="idp--wrapper">
          <Typography.Title level={2} style={{ marginBottom: 0 }}>Reset password.</Typography.Title>
          <Typography.Paragraph style={{ marginBottom: '1.6rem' }}>Choose a new password for your TimeEdit account.</Typography.Paragraph>
          {resetError && (
            <Alert
              message="Unable to reset your password"
              description={resetError.message}
              type="error"
            />
          )}
          <Form layout="vertical" form={form} onFinish={onResetPassword} requiredMark={false}>
            <Form.Item
              label="Password:"
              style={{ marginBottom: '0.2rem' }}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
                onChange={(e) => onPasswordChange(e.target.value)}
              />
              <PasswordStrength
                strength={pwSheriff.strength}
                acceptableStrength={pwSheriffRules.length}
                maxStrength={pwSheriffRules.length}
                errors={pwSheriff.errors}
              />
            </Form.Item>
            <Form.Item
              name="passwordMatch"
              label="Confirm password:"
              dependencies={['password']}
              hasFeedback
              rules={[
                { required: true, message: "Please confirm your new password" },
                () => ({
                  validator(_, value) {
                    if (!value || password === value) {
                      setHasMatchingPasswords(true);
                      return Promise.resolve();
                    }
                    setHasMatchingPasswords(false);
                    return Promise.reject(new Error('The two passwords that you entered do not match'));
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Reenter password"
              />
            </Form.Item>
            <Button disabled={!pwCheckValid()} block htmlType="submit" type="primary">Reset password</Button>
          </Form>
        </div>
      )}
      {successfulReset && (
        <div className="idp--wrapper">
          <Typography.Title level={2} style={{ marginBottom: 0 }}>Success!</Typography.Title>
          <Typography.Paragraph style={{ marginBottom: '1.6rem' }}>Your password has been successfully reset</Typography.Paragraph>
          <Link to="/">
            <Button type="primary" block onClick={() => history.push('/')}>Go back to sign in</Button>
          </Link>
        </div>
      )}
    </LoadingSpinner>
  );
}

export default TEIdPResetPasswordPage;

import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

// HOOKS
import { useParsedQuery } from "../../hooks/useQuery";

// REDUX
import { fetchOrganizations, organizationsInitialLoad, organizationsLoading, selectOrganizationFromCustomerSignatureAndAppId } from "../../slices/organization.slice";

// TYPES
import { EAppId } from "@timeedit/types/lib/enums";
import { useEffect } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";

const DeepLinkAuthPage = () => {
  /**
   * QUERY PARSER
   */
  const [customerSignature, appId, context] = useParsedQuery(['customerSignature', 'appId', 'context']);
  const dispatch = useDispatch();
  const organization = useSelector(selectOrganizationFromCustomerSignatureAndAppId(customerSignature, appId as EAppId));
  const isLoading = useSelector(organizationsLoading);
  const hasCompletedInitialLoad = useSelector(organizationsInitialLoad);
  useEffect(() => {
    if (!organization) {
      dispatch(fetchOrganizations());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  if (hasCompletedInitialLoad && !isLoading && !organization) {
    const message = 'Invalid organization or application';
    const description = 'We couldn\'t find your organization, or your organization does not have access to the application you requested';
    const query = new URLSearchParams({ message, description });
    return <Redirect to={`/unsuccessful-authentication?${query.toString()}`} />;
  }
  if (hasCompletedInitialLoad && !isLoading && organization) {
    return <Redirect to={`/organizations/${organization?.id}/products/${appId}?${new URLSearchParams({ context: context || '' }).toString()}`} />
  }

  return <LoadingSpinner isLoading children={null} />
};

export default DeepLinkAuthPage;


import { useLocation } from "react-router-dom";

// COMPONENTS
import BackButton from "../BackButton";
import TELogo from "../TELogo";
import TopBar from "../TopBar";

// CONSTANTS
import pagesWithDisabledBackButton from "../../constants/disableBackButtonPages.constants";

// STYLES
import "./index.scss";

// TYPES
type Props = {
  children: React.ReactNode;
};

const MainLayoutComponent = ({ children }: Props) => {
  const { pathname } = useLocation();
  return (
    <div className="main-layout--wrapper">
      <TopBar />
      <div className="main-layout--container-wrapper">
        <div className="main-layout--container">
          <div className="main-layout--header">
            <TELogo />
            {pagesWithDisabledBackButton.indexOf(pathname) === -1 && (
              <BackButton />
            )}
          </div>
          <div className="main-layout--content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MainLayoutComponent;
